section.paragraph-teaser-image {
  h3 {
    @include body(l);
  }
  .text {
    margin: rem-calc(40) 0 rem-calc(60) 0;
    p {
      @include body(s);
      margin: 0;
    }
  }
}
