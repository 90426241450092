.paragraph-media {
  video {
    width: 100%;
  }
  .local-video {
    position: relative;
    .playpause {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      cursor: pointer;
      border-radius: 50%;

      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      transition-delay: 0s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
    }
  }
  &.video-playing {
    .local-video {
      .playpause {
        opacity: 0;
        transition-delay: 0.5s;
      }
      &:hover {
        .playpause {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
}

.iframe-wrap {
  position: relative;
  &.iframe-16-9 {
    padding: 56.25% 0 0 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
