.error-msg {
  color: var(--error);
  @include body(xxs);
  margin-bottom: var(--module-padding-m);
}

.system-page {
  padding: var(--module-padding-l) 0;
  label {
    @include body(xs);
  }

  span.required::after {
    content: "*";
    color: var(--error);
  }
}

.form-login,
.form-register {
  .help-links {
    margin-top: var(--module-padding-m);
    display: flex;
    flex-direction: column;

    a,
    p {
      @include body(xxs);
    }

    a {
      color: var(--theme-1);
    }
  }
}

.node-intern {
  padding-top: var(--module-padding-l);

  .content-wrap > *:first-child section.paragraph {
    padding-top: 0;
    margin-top: 0;
  }
}

.sidebar {
  position: sticky;
  top: rem-calc(100);
  transition: 0.3s top;
}

.user-menu {
  margin-top: var(--module-padding-l);
  background-color: var(--theme-1-lighter-30);

  li.active a {
    color: var(--white);
    background-color: var(--theme-1);
  }
}

.card-user-status {
  background-color: var(--theme-1-lighter-30);

  p {
    padding: 20px;
  }
}

.user-menu,
.card-user-status {
  a,
  button,
  span.expandable {
    display: block;
    padding: 10px 20px;
    color: var(--theme-1-darker-30);
    border: none;
    border-top: 1px solid var(--white);
    width: 100%;
    background-color: transparent;
    text-align: left;
    @include body(xs);

    &:hover {
      background-color: var(--theme-1-lighter-20);
      text-decoration: none;
    }

    &:focus {
      outline: 2px solid var(--theme-1-darker-30);
      outline-offset: -2px;
    }
  }

  span.expandable {
    padding-right: rem-calc(40);
    cursor: pointer;

    &::after {
      content: "+";
      position: absolute;
      right: rem-calc(20);
    }

    &.expanded::after {
      content: "-";
    }
  }

  .level-2 {
    background-color: var(--theme-1-lighter-25);

    a,
    button,
    span.expandable {
      @include body(xxs);
    }
  }
}

body {
  &.top .sidebar {
    top: rem-calc(170);
  }
}
