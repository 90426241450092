.card {
  border-radius: 0;
  border: 0;
  margin-bottom: var(--module-padding-m);
  width: 100%;
  height: 100%;

  .card-head {
    position: relative;
    overflow: hidden;

    .background-image {
      width: 100%;
      height: 100%;
      transition: 0.3s transform;
    }

    .logo-wrapper {
      position: absolute;
      top: var(--module-padding-s);
      left: var(--module-padding-s);
      width: rem-calc(64);
      height: rem-calc(64);
    }
  }

  .title-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--module-padding-s);
    background-color: var(--theme-1-transparent);

    h3 {
      color: var(--white);
      margin: 0;
    }
  }

  &:hover {
    .background-image {
      transform: scale(1.1);
    }
  }

  .card-body {
    padding: var(--module-padding-s);
    background-color: var(--theme-1-lighter-30);
    flex-grow: 1;
  }

  .card-footer {
    padding: 0;
    border: 0;
    background-color: transparent;

    .btn {
      width: 100%;
      padding: rem-calc(5) var(--module-padding-s);
    }
  }
}
