.fade-wrapper-click-suggestions_detached + .fade-wrapper-call-to_action {
  .paragraph,
  .content {
    padding-top: 0 !important;
  }
}

section.paragraph.paragraph-click-suggestions {
  background-color: var(--theme-1);
  padding: var(--module-padding-l) 0;

  .row {
    row-gap: var(--module-padding-l);
  }

  .click-suggestions-box {
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &:hover {
        text-decoration: none;

        .footer {
          background-color: var(--theme-2);
          border-bottom-color: var(--theme-2-lighter-20);
        }
      }
    }

    .body {
      padding: var(--module-padding-s);
      padding-bottom: var(--module-padding-l);
      background-color: var(--white);
      height: 100%;

      p {
        @include body(xs);
        color: var(--black);
      }
    }

    .footer {
      .btn {
        width: 100%;
      }
    }
  }

  .click-suggestions-column {
    .body {
      h3 {
        color: var(--white);
        text-align: center;
      }

      p {
        @include body(xs);
        color: var(--white);
        text-align: center;
      }
    }

    .footer {
      margin-top: var(--module-padding-m);
      text-align: center;
    }
  }
}
