.fade-wrapper-text + .fade-wrapper-section-heading section.paragraph.paragraph-sectionheading,
.fade-wrapper-text + .fade-wrapper-hero-text section.paragraph.paragraph-hero-text,
.fade-wrapper-text + .fade-wrapper-bilder-slider section.paragraph.paragraph-bilder-slider,
.fade-wrapper-text + .fade-wrapper-media section.paragraph.paragraph-media {
  padding-top: 0;
}

.fade-wrapper-text + .fade-wrapper-bilder-slider section.paragraph.paragraph-bilder-slider {
  margin-top: calc(-1 * var(--module-padding-m));
}

section.paragraph.paragraph-text {
  padding: var(--module-padding-l) 0;

  &.background-color {
    .text {
      padding: rem-calc(30) rem-calc(40);
      background: $white;
    }
  }
  .text {
    @include body(xs);
    p, ul, ol {
      @include body(xs);
    }
  }
}
