section.paragraph.paragraph-dossier {
  background: $white;
  position: relative;
  overflow: hidden;
  &.with-background-image {
    background: none;
  }
  .background-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }
  h2 {
    margin-bottom: rem-calc(65);
    @include body(l);
  }
  .row.items {
    > div {
      margin-bottom: rem-calc(65);
    }
    @include media-breakpoint-up(md) {
      > div {
        &:nth-child(1) {
          margin-top: rem-calc(65);
        }
        &:nth-child(3) {
          margin-top: rem-calc(130);
        }
      }
    }
  }
}
