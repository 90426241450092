section.paragraph.paragraph-teaser-slider {
  padding: 0;
  .slick-slider {
    .slick-arrow {
      top: 35%;
      transform: translate(0);
    }
    .slick-prev {
      left: 7%;
    }
    .slick-next {
      transform: scaleX(-1);
      right: 7%;
    }
    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0 20px;
        button {
          background: $light-blue;
          height: rem-calc(8);
          width: rem-calc(40);
          overflow: hidden;
          color: $light-blue;
          border: none;
          transition-duration: var(--transition-duration);
          &:hover {
            background: $dark-blue;
            color: $dark-blue;
            transform: scale(1.05);
          }
        }

        &.slick-active {
          button {
            background: $dark-blue;
            color: $dark-blue;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .slick-arrow {
        top: auto;
        bottom: 5%;
      }
    }
    .slick-slide {
      @include media-breakpoint-up(md) {
        padding: $paragraph-section-padding;
      }
      position: relative;
      .background-image {
        position: relative;
        top: 0;
        left: 10%;
        max-width: 80%;
        max-height: 60vh;
        height: auto;
        width: 100%;
        z-index: 1;
        object-fit: cover;
        object-position: center;
      }
      article.container {
        padding: rem-calc(15) 0 5% 0;
        @include media-breakpoint-up(md) {
          min-height: 60vh;
          padding: rem-calc(60) rem-calc(30) rem-calc(20) rem-calc(30);
        }
        position: relative;
        .row {
          position: relative;
        }
        h3 {
          @include headline(m);
          margin-bottom: rem-calc(15);
          @include media-breakpoint-up(md) {
            max-width: 60%;
            margin-bottom: rem-calc(65);
          }
        }
        .teaser-txt {
          padding-top: var(--module-padding-l);
          column-count: 2;
        }
        p.pager {
          @include body(xs);
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
