.btn {
  border-radius: 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  @include body(xs);
  font-weight: bold;

  &.btn-primary {
    background-color: var(--theme-2);
    border-bottom: 5px solid var(--theme-2-lighter-20);
  }

  &.btn-secondary {
    background-color: var(--theme-1-lighter-10);
    border-bottom: 5px solid var(--theme-1-lighter-20);

    &:hover {
      background-color: var(--theme-2);
    }
  }

  &:hover {
    &::after {
      transform: translateX(5px);
    }
  }

  &::after {
    content: "\e600";
    font-family: "Malek";
    font-size: rem-calc(10);
    margin-left: var(--module-padding-m);
    transition: var(--transition-duration) transform;
  }

  &.btn-back {
    &::after {
      transform: rotate(180deg);
    }

    &:hover::after {
      transform: translateX(-5px) rotate(180deg);
    }
  }
}

span.error-message {
  @include body(xxs);
}

.form-control {
  border-radius: 0;
  background-color: transparent;

  &:focus {
    background-color: transparent;
    border-color: var(--theme-1);
    box-shadow: 0 0 0 0.2rem var(--theme-1-lighter-20);
  }
}
