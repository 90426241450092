section.paragraph.paragraph-image-teaser-list {
  .row {
    row-gap: var(--module-padding-xl);
  }

  .image-teaser {
    display: flex;
    flex-direction: column;
    height: 100%;

    .teaser-body {
      padding: var(--module-padding-m);
      background-color: var(--theme-1-lighter-30);
      height: 100%;
    }
  }
}
