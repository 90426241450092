.icon-malek {
  font-family: "Malek";
  font-size: inherit;

  &.arrow-right::before {
    content: "\e600";
  }

  &.letter::before {
    content: "\e602";
  }

  &.phone::before {
    content: "\e604";
  }

  &.person::before {
    content: "\e900";
  }
}

.icon {
  display: inline-block;
  width: rem-calc(24);
  height: rem-calc(24);
  position: relative;
  background-size: contain;

  &.icon-phone {
    background-image: url("/icon-phone.svg");
  }

  &.icon-contact {
    background-image: url("/icon-contact.svg");
  }

  &.icon-support {
    background-image: url("/icon-support.svg");
  }

  &.icon-login {
    background-image: url("/icon-login.svg");
  }
}
