.fade-wrapper-text + .fade-wrapper-section-heading section.paragraph.paragraph-sectionheading,
.fade-wrapper-text + .fade-wrapper-hero-text section.paragraph.paragraph-hero-text {
  padding-top: 0;
}

section.paragraph.paragraph-text {
  padding: var(--module-padding-l) 0;

  &.background-color {
    .text {
      padding: rem-calc(30) rem-calc(40);
      background: $white;
    }
  }
  .text {
    @include body(xs);
    p, ul, ol {
      @include body(xs);
    }
  }
}
