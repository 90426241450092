/**
 * Full page.
 */
article.node-person.node-full-page {
  .paragraph-person-information {
    .node-teaser.teaser-person.teaser-overlay {
      img {
        margin-bottom: 0;
      }
    }
  }
}

/**
 * Teaser Views modes.
 */
article.node-teaser.teaser-person {
  .contact {
    margin-top: rem-calc(12);

    .mail,
    .phone,
    .mobil {
      display: flex;
    }
  }

  .mail,
  .phone,
  .mobil {
    color: $link-color;
  }

  .info {
    margin-top: rem-calc(12);
  }

  .buttons {
    margin-top: rem-calc(24);

    a.button {
      @include button($black, $primary-color, $black);
    }
  }

  &.teaser-person-overview {
    cursor: pointer;

    .mail,
    .phone {
      display: block;
    }

    .mail {
      margin-top: rem-calc(20);
    }

    .role {
      .top-line {
        display: inline-block;
      }
    }
  }

  &.teaser-overlay {
    display: block;

    h1 {
      @include headline(l);
      margin-bottom: rem-calc(10);
    }

    .top-line {
      @include body(s);
      letter-spacing: rem-calc(0.16);
    }

    .text {
      margin-top: rem-calc(65);
      margin-bottom: rem-calc(65);
    }

    .label,
    .value {
      @include body(xs);

      &.margin-bottom {
        margin-bottom: rem-calc(10);
      }
    }

    .infos {
      a {
        color: $primary-color-active;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

article.node-teaser.teaser-person-kontakt {
  h3 {
    margin-bottom: 0;
  }

  .contact {
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(15);

    span,
    a {
      display: block;
      color: $link-color;
    }
  }

  .info p:last-child {
    margin-bottom: 0;
  }

  .buttons {
    margin-top: rem-calc(25);
  }
}

.person-overlay-bg {
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .person-overlay {
    max-height: 80vh;
    overflow: auto;
    padding: rem-calc(60) 0 rem-calc(120) 0;
    left: 0;
    width: 100%;
    background: white;
    top: 0;

    .node-teaser {
      img {
        margin-bottom: 0;
      }
    }

    .close {
      cursor: pointer;
    }
  }
}
