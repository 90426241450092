.language-switch {
  margin-left: 2rem;
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding-left: 10px;
  padding-right: 15px;
  a {
    display: block;
    font-size: 1.5rem;
    color: $dark;
    &.active {
      display: none;
    }
    &.not-translated {
      cursor: default;
      color: $primary-color;
      pointer-events: none;
      text-decoration: line-through;
    }
  }
}