$font-family-base: "Clear Sans", sans-serif;

// Clear Sans Light
@font-face {
  font-family: "Clear Sans";
  src: url("../fonts/ClearSans-Light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

// Clear Sans Regular
@font-face {
  font-family: "Clear Sans";
  src: url("../fonts/ClearSans-Regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

// Clear Sans Bold
@font-face {
  font-family: "Clear Sans";
  src: url("../fonts/ClearSans-Bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Malek";
  src: url("../fonts/malek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin body($type) {
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  font-weight: 400;
  line-height: 1.4;

  @if $type == xxs {
    letter-spacing: 0.3px;
    font-size: rem-calc(12);
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(14);
    }
  }

  @if $type == xs {
    letter-spacing: 0.15px;
    font-size: rem-calc(16);
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(19);
    }
  }

  @if $type == s {
    font-size: rem-calc(18);
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24);
    }
  }

  @if $type == m {
    font-size: rem-calc(20);
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(32);
    }
  }

  @if $type == l {
    font-size: rem-calc(24);
    line-height: 1.375;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(36);
    }
  }
}

@mixin headline($type, $font-weight: 300) {
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  font-weight: $font-weight;
  line-height: 1.4;
  
  @if $type == s {
    font-size: rem-calc(20);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(24)
    }
  }

  @if $type == m {
    font-size: rem-calc(24);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(28)
    }
  }

  @if $type == l {
    font-size: rem-calc(32);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(40);
    }
  }

  @if $type == xl {
    font-size: rem-calc(42);
    font-weight: 700;
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(64);
    }
  }
}
