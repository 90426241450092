section.paragraph.paragraph-download {
  h3 {
    @include body(m);
    margin-bottom: rem-calc(20);
  }
  a {
    align-self: baseline;
    padding: rem-calc(11);
    min-width: unset;
    svg {
      fill: white;
    }
  }
}
