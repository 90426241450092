.main-content > main {
  padding-top: 85px;

  @include media-breakpoint-up(lg) {
    padding-top: 150px;
  }
}

#adminApp header#site-header {
  margin-top: -55px;

  &.top {
      margin-top: -15px;
  }
}

header#site-header {
  --main-menu-color-bg: var(--white);
  --main-menu-color-text: var(--theme-1);
  --level-2-color-bg: var(--white);
  --level-2-color-text: var(--theme-2);
  --mega-menu-color-bg: var(--theme-1);
  --mega-menu-color-text: var(--white);

  &::before {
    content: "";
    position: absolute;
    height: rem-calc(10);
    width: 100%;
    left: 0;
    top: 0;
    transition: 0.3s top;
    background-color: var(--theme-2);
    z-index: 100;
  }

  position: fixed;
  width: 100%;
  z-index: 99;

  .desktop-header {
    transform: translateY(0) !important;
    background-color: white;
    transition: 0.3s all;

    .logo-wrapper {
      transition: 0.3s all;
      margin-top: rem-calc(-65);
      max-width: rem-calc(150);
      z-index: 1;
    }
  }

  &.top {
    .desktop-header {
      transform: translateY(0) !important;

      .main-menu-wrapper {
        margin-top: 0;
      }

      .logo-wrapper {
        margin-top: rem-calc(-25);
        max-width: rem-calc(200);
      }
    }

    &::before {
      top: 0;
    }
  }

  .mobile-header {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(15);

      .logo-wrapper {
        max-width: rem-calc(160);
      }
    }
  }
}

@mixin level-1-link {
  font-family: $font-family-base;
  font-weight: bold;
  font-size: rem-calc(28);
  color: var(--main-menu-color-text);

  &:hover {
    text-decoration: none;
  }
}

@mixin level-2-link {
  @include body(xs);
  color: var(--main-menu-color-text);
}

.main-menu-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-menu-wrapper {
  background-color: var(--main-menu-color-bg);
  margin-top: rem-calc(-100);
  z-index: 0;
  position: relative;
  transition: 0.3s margin-top;

  .level-1 {
    display: flex;
    gap: rem-calc(50);
    margin-bottom: 0;

    > li {
      position: relative;
      padding: rem-calc(20) 0;

      &.expanded {
        .level-2 {
          display: block;
        }
      }

      > a {
        @include level-1-link;
      }
    }
  }

  .level-2 {
    display: none;
    position: absolute;
    z-index: 90;
    background-color: var(--level-2-color-bg);
    color: var(--level-2-color-text);
    max-width: rem-calc(500);
    width: 100%;
    min-width: rem-calc(250);
    margin: rem-calc(20) 0 0 rem-calc(-20);
    padding: rem-calc(20);
    border-top: 5px solid var(--theme-1-lighter-20);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    > li {
      margin: rem-calc(5) 0;

      > a {
        @include level-2-link;
        display: inline-block;
      }
    }
  }
}

.header-meta-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: rem-calc(24);
  padding: rem-calc(30) 1rem rem-calc(25) 1rem;
  background-color: white;
  z-index: 1;

  span {
    display: inline-block;
    line-height: 0;
  }

  a {
    display: inline-block;
    line-height: 0;
    color: var(--theme-1);
    font-weight: bold;
  }

  .header-meta-text {
    margin: 0;
    @include body(xs);
    color: var(--main-menu-color-text);

    p {
      margin: 0;
    }
  }

  .user-status-block {
    position: absolute;
    background-color: var(--theme-1);
    color: white;
    max-width: rem-calc(300);
    width: rem-calc(300);
    line-height: normal;
    margin-left: rem-calc(-18);
    border-top: rem-calc(20) solid white;

    p {
      padding: rem-calc(10) rem-calc(20);
      margin: 0;
    }

    &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: -20px;
        left: 20px;
        border-bottom: rem-calc(10) solid var(--theme-1);
        border-left: rem-calc(10) solid transparent;
        border-right: rem-calc(10) solid transparent;
    }

    ul {
      margin-bottom: 0;
    }
    
    a, button {
        color: var(--white);
        padding: rem-calc(10) rem-calc(20);
        background-color: transparent;
        border: none;
        font-weight: normal;
        font-size: 1rem;
        width: 100%;
        display: block;
        line-height: normal;
        display: block;
        text-align: left;
        border-top: 1px solid var(--theme-1-lighter-10);

        &:hover {
          background-color: var(--theme-1-lighter-10);
          text-decoration: none;
        }
    }
  }
}

.mega-menu-wrapper {
  position: absolute;
  z-index: 90;
  width: 100%;

  .mega-menu {
    background-color: var(--mega-menu-color-bg);
    padding: rem-calc(50) 0;
    display: none;

    &.expanded {
      display: block;
    }

    .mega-menu-list {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-auto-rows: auto;
      gap: $grid-gutter-width;
    }

    .mega-menu-item {
      a {
        display: flex;
        flex-direction: column;
        gap: $grid-gutter-width;
      }

      * {
        color: var(--mega-menu-color-text);
      }

      &:hover * {
        text-decoration: none;
      }

      .icon {
        width: rem-calc(64);
        height: rem-calc(64);
      }
    }
  }
}

.mobile-header {
  background-color: var(--white);
}

.mobile-menu-wrapper {
  position: absolute;
  min-height: calc(100svh - 87px);
  height: 0;
  width: 100%;
  left: 100%;
  overflow-y: auto;
  transition: 0.3s left;
  background-color: var(--white);
  padding-top: rem-calc(20);
  display: flex;
  flex-direction: column;

  .main-navigation {
    flex-grow: 1;
  }

  .social {
    gap: var(--module-padding-m);
  }

  a {
    color: var(--theme-1);
  }

  &.open {
    left: 0;
  }

  a {
    margin-bottom: 10px;
    display: block;
  }

  .level-1 {
    > li:not(:first-child) {
      margin-top: rem-calc(30);
    }

    a {
      @include body(m);
      color: var(--theme-1);
    }
  }

  .level-2 {
    a {
      @include body(s);
      color: var(--theme-1);
    }
  }
}
