.toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;

  .toast {
    margin-right: var(--module-padding-m);
    margin-bottom: var(--module-padding-m);
    border-radius: 0;
    border: 0;
    background-color: var(--white);

    .toast-header {
        justify-content: space-between;
        background-color: transparent;
        color: var(--black);
    }

    .toast-close {
      border: 0;
      background-color: transparent;
    }
  }
}
