section.paragraph.paragraph-google-maps {
  position: relative;

  .google-maps-wrapper {
    background: url("/google-maps-preview.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    min-height: rem-calc(400);
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      min-height: rem-calc(400);
      display: block;
    }
  }

  .consent {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: var(--module-padding-m);
    text-align: center;
    z-index: 1;
  }
}
