article.node-blog {
  margin-top: var(--module-padding-l);

  .btn-back {
    margin-bottom: var(--module-padding-l);
  }

  .section-head {
    margin-bottom: var(--module-padding-m);

    .meta {
      display: flex;
      flex-direction: column;
      gap: var(--module-padding-s);
      @include body(xxs);
      color: var(--theme-1);
    }

    .tags {
      display: inline-flex;
      gap: var(--module-padding-xs);

      .tag {
        padding: 0 5px;
        border: 1px solid var(--theme-1);
      }
    }
  }

  .section-summary {
    margin-bottom: var(--module-padding-m);
  }

  .section-text {
    margin-bottom: var(--module-padding-l);

    .align-center img {
      display: block;
      margin: 0 auto;
    }
    .align-left {
      margin-right: 1.5rem;
    }
    .align-right {
      margin-left: 1.5rem;
    }

    figcaption {
      @include body(xxs);
    }

    p.hero-text {
      @include body(s);
      color: var(--theme-1);
    }

    ul li::marker {
      color: var(--theme-2);
    }

    ul li ul li::marker {
      color: var(--theme-1);
      content: "– ";
    }

    h2,
    .headline-l {
      @include headline(l);
      color: var(--theme-1);
    }

    h3,
    .headline-m {
      @include headline(m);
      color: var(--theme-1);
    }

    h4,
    .headline-s {
      @include headline(s);
      color: var(--theme-1);
    }
  }
}

.visually-hidden {
  display: none;
}

article.node-teaser.teaser-blog {
  a {
    flex-direction: column;
    row-gap: var(--module-padding-xs);
    margin-bottom: var(--module-padding-l);
    margin-left: rem-calc(-20);
    margin-right: rem-calc(-20);
    padding: rem-calc(20);

    &:hover {
      background-color: var(--theme-1-lighter-30);
    }

    > * {
      margin-bottom: 0;
    }
    .meta {
      display: flex;
      flex-direction: column;
      gap: var(--module-padding-s);
      @include body(xxs);
      color: var(--theme-1);
    }

    .tags {
      display: inline-flex;
      gap: var(--module-padding-xs);

      .tag {
        padding: 0 5px;
        border: 1px solid var(--theme-1);
      }
    }
  }

  .d-flex {
    gap: var(--module-padding-s);

    img {
      width: unset;
    }
  }
}

.blog-modules section.paragraph {
  padding: var(--module-padding-s) 0 !important;
}