.fade-wrapper-seitentitel + .fade-wrapper-hero-text section.paragraph.paragraph-hero-text {
  padding-top: 0;
}

section.paragraph.paragraph-page-title {
  padding: var(--module-padding-xl) 0 var(--module-padding-m) 0;

  h1 {
    font-size: rem-calc(42);
    font-weight: 300;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(48);
    }
    color: var(--theme-1);
  }
}
