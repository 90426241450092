section.filter-cloud {
  padding: rem-calc(50) 0;
  .filter {
    background-color: $pale-grey;
    color: $dark;
    @include body(xs);
    border-radius: rem-calc(12);
    padding: rem-calc(4) rem-calc(8);
    display: inline-block;
    margin-right: rem-calc(15);
    margin-bottom: rem-calc(15);
    cursor: pointer;
    border: rem-calc(1) solid transparent;
    &.active {
      border: rem-calc(1) solid $dark;
    }
  }
}

section.search {
  margin: $paragraph-section-padding;
}

section.filter-text,
section.filter-dialog-baum {
  margin: $paragraph-section-padding;
  p {
    @include body(l);
    display: inline;
  }
  &.filter-dialog-baum {
    p {
      vertical-align: middle;
    }
  }
  .bx--form-item {
    display: inline;
    margin: 0 rem-calc(30) 0 rem-calc(15);
    .bx--select {
      display: inline-block;
    }
  }
  div.filter {
    cursor: pointer;
    display: inline-block;
    background: $pale-grey;
    border-radius: rem-calc(32);
    padding: rem-calc(4) rem-calc(18);
    margin: 0 rem-calc(15);
    &.active {
      background: $pale-sky-blue;
      span {
        color: $primary-color;
        svg {
          padding: 0 0 rem-calc(5) 0;
          fill: $primary-color;
        }
      }
    }
    span {
    }
  }
}

.pagination {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    li {
      cursor: pointer;
      a {
        display: table;
        padding: rem-calc(15);
        color: black;
      }
      &.selected {
        border: rem-calc(1) solid $primary-color;
        a {
          color: $primary-color;
        }
      }
      &.previous,
      &.next {
        min-width: auto;
        padding: 0;
        a {
          color: white;
        }
      }
      &.previous {
        margin-right: auto;
      }
      &.next {
        margin-left: auto;
      }
      &.disabled {
        visibility: hidden;
      }
    }
  }
}

.paragraph.paragraph-extended-teaser-overview {
  article.node-teaser.teaser-person.teaser-person-overview {
    margin-bottom: rem-calc(70);
  }
  article.node-teaser.teaser-projekt,
  article.node-teaser.teaser-news {
    margin-bottom: rem-calc(30);
  }
  &.type-projekt {
    @include media-breakpoint-up(md) {
      .col-md-custom {
        max-width: 33.333%;
        width: 33.333%;
        flex: 0 0 33.333%;
      }
      .col-md-custom-highlighted {
        max-width: 100%;
        width: 100%;
        padding: 0 25%;
        //margin: 0 25%;
      }
      .small_big {
        .col-16:not(.col-md-5) {
          @include make-col(11);
        }
      }
    }
  }
  &.type-veranstaltung {
    article.node {
      margin-bottom: 2rem;
    }
  }
}
