/* Reset and normalize */
@import "~css-reset-and-normalize/scss/reset-and-normalize.scss";

/* recaptcha */
@import "../../node_modules/react-client-captcha/dist/index.css";

/* react loading spinner */
@import "../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/*7 React Lightbox */
@import "../../node_modules/lightbox-react/style.css";

/* Carbon */
@import "~carbon-components/scss/components/select/select";
@import "~carbon-components/scss/components/search/search";
@import "~carbon-components/scss/components/button/button";
@import "~carbon-components/scss/components/notification/inline-notification";

/* react-accessible-accordion */
//@import '~react-accessible-accordion/dist/fancy-example.css';

/* Slick SCSS */
$slick-font-path: "/font/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick-prev,
.slick-next {
  z-index: 1 !important;
  width: rem-calc(25);
  height: rem-calc(25);

  &::before {
    content: none;
  }

  &:focus,
  &:focus-visible {
    outline: rem-calc(1) solid black;
  }

  svg {
    width: rem-calc(25);
    height: rem-calc(25);
  }
}

.slick-prev {
  left: rem-calc(20);
}

.slick-next {
  right: rem-calc(20);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: #000a;

  .lightbox-inner {
    width: 90%;
    height: 90%;
    max-height: 90vh;
    margin-left: 5%;
    margin-top: 5%;
    object-fit: contain;

    img {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: contain;
    }
  }
}

@import "~bootstrap/scss/bootstrap";
@import "_mixins";
@import "forms";
@import "theme";
@import "icons";
@import "controls";

// Components.
@import "footer";
@import "header";
@import "components/card";
@import "eforkbot";
@import "components/fullscreen-menu";
@import "components/paragraph-hero-text";
@import "components/paragraph-click-suggestions";
@import "components/paragraph-teaser-list";
@import "components/paragraph-teaser-image";
@import "components/paragraph-sectionheading";
@import "components/paragraph-text";
@import "components/paragraph-intro";
@import "components/paragraph-icon-text";
@import "components/paragraph-number-fact";
@import "components/paragraph-teaser-slider";
@import "components/paragraph-newsletteranmeldung";
@import "components/paragraph-akkordion-text";
@import "components/paragraph-dossier";
@import "components/paragraph_page-title";
@import "components/paragraph-image-teaser-list";
@import "components/paragraph_call-to-action";
@import "components/paragraph-extended-teaser-overview";
@import "components/paragraph-calendar-information";
@import "components/paragraph-download";
@import "components/paragraph-zitat";
@import "components/footer-sitemap";
@import "components/pager-full-page";
@import "components/paragraph-download-schublade";
@import "components/paragraph-timeline";
@import "components/paragraph-strukturierte-liste";
@import "components/paragraph-simple-teaser-list";
@import "components/paragraph-kalender-einzel-doppel";
@import "components/paragraph-kalender-abschnitt";
@import "components/paragraph-kalender-monat";
@import "components/paragraph-media";
@import "components/paragraph-audio";
@import "components/paragraph-tabs";
@import "components/paragraph-logos-partners";
@import "components/content-cloud/paragraph-content-cloud";
@import "components/content-cloud/detail-overlay";
@import "components/paragraph-text-and-speech";
@import "components/paragraph-google-maps";
@import "components/paragraph-content-slider";
@import "components/paragraph-dashboard";
@import "webform";
@import "components/language-switch";
@import "components/commerce";
@import "components/paragraph-intro-slider";
@import "components/toast";

@import "intern";

// Nodes.
article.node-teaser {
  display: flex;

  .flex-wrap {
    display: block;
    width: 100%;
  }

  .top-line {
    display: block;
    @include body(xs);
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  &.teaser-news a {
    color: var(--theme-1);
    &:hover {
      text-decoration: underline;
    }
  }
}

@import "nodes/node-blog";
@import "nodes/node-projekt";
@import "nodes/node-landingpage";
@import "nodes/node-person";
@import "nodes/node-event";
@import "nodes/node-news";
@import "nodes/node-solution";
@import "nodes/node-product";

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

html,
body {
  font-size: inherit;
}

#app {
  //overflow-x: hidden;
  > * {
    @include body(s);
  }
}

.paragraph {
  padding: $paragraph-section-padding-mobile;
  @include media-breakpoint-up(md) {
    padding: $paragraph-section-padding;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    width: rem-calc(1600);
  }
}

article.node-teaser {
  position: relative;
}

.caption-wrap {
  margin-top: rem-calc(10);
  @include body(xxs);

  .caption + .credit {
    margin-left: rem-calc(5);
  }
}

.text {
  &,
  p {
    @include body(xs);
  }

  ul {
    list-style: disc;
    margin-left: rem-calc(20);

    li {
      padding-left: rem-calc(5);
    }
  }

  ol {
    list-style: decimal;
    margin-left: rem-calc(20);

    li {
      padding-left: rem-calc(5);
      padding-bottom: rem-calc(15);
    }
  }

  ul li::marker {
    color: var(--theme-2);
  }

  ul li ul li::marker {
    color: var(--theme-1);
    content: "– ";
  }

  a {
    color: var(--theme-1);
  }
}

b,
strong {
  font-weight: bold;
}

.big,
.big * {
  @include body(s);
}

small,
.small,
small * {
  @include body(xxs);
}

h1,
.headline-xl {
  @include headline(xl);
  color: var(--theme-1);
}

h2,
.headline-l {
  @include headline(l);
  color: var(--theme-1);
}

h3,
.headline-m {
  @include headline(m);
  color: var(--theme-1);
}

h4,
.headline-s {
  @include headline(s);
  color: var(--theme-1);
}

/*TODO: Plex Sans Light for headings*/

.loading-indicator-wrap {
  width: 100%;
  height: 80svh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: rem-calc(200);
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;

  > main {
    flex-grow: 1;
  }
}

a.download-link {
  display: flex;
  gap: rem-calc(5);

  &::before {
    content: url("/icon-download.svg");
    width: rem-calc(20);
    height: rem-calc(20);
    font-size: rem-calc(20);
  }
}

sup {
  font-size: 55%;
}

div[id*="block-module-box-local-tasks"] {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  border: 0 !important;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1px;
    box-shadow: 0px 5px 10px #0003;

    li {
      a {
        padding: 15px 25px !important;

        &:hover {
          background-color: var(--gin-bg-layer2);
        }
      }
    }
  }
}
