section.paragraph.paragraph-newsletter-register {
  position: relative;
  h3 {
    @include headline(m);
    margin-bottom: rem-calc(25);
  }
  .loading-indicator-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
