.add-to-cart {
  button[type="submit"] {
    margin-top: 0;
  }
}

.cart {
  .row.header-row,
  .row.product-row {
    border: 1px solid $dark;

    > [class^="col-"] {
      padding: 0.25rem;
      border-right: 1px solid $dark;

      &:last-child {
        border-right: none;
      }
    }
  }

  .row.totals {
    margin-top: 1rem;

    h4 {
      font-size: 1.25rem;
    }
  }
}

.checkout {
  form {
    label {
      margin-top: 1.5rem;
      display: block;
    }

    input {
      width: 100%;
    }
  }
}
