section.paragraph.paragraph-kalender-monat {
  position: relative;
  h3.calendar-heading {
    @include headline(m);
    text-align: center;
    margin-bottom: rem-calc(40);
  }
  .pager {
    cursor: pointer;
  }
  .row.header {
    margin-bottom: rem-calc(40);
    position: relative;
    padding: 0;
    top: auto;
    left: auto;
    right: auto;
    .cal-header-cell {
      @include body(s);
      span {
        padding-left: rem-calc(20);
      }
    }
  }
  .cal-cell {
    @include body(m);
    span {
      padding: rem-calc(7) rem-calc(20) rem-calc(40) rem-calc(20);
      display: table;
      pointer-events: none;
      &:after {
        content: "";
        text-align: center;
        width: rem-calc(14);
        height: rem-calc(14);
        border-radius: 50%;
        border: rem-calc(1) solid $pinkish-grey;
        display: block;
        margin: 0 auto;
      }
    }
    &.has-events {
      span {
        pointer-events: all;
        cursor: pointer;
        &:after {
          background-color: $primary-color-active;
        }
      }
    }
    &.active {
      span {
        background: $white;
      }
    }
  }
  .cal-content-cell {
    &.active {
      padding-top: rem-calc(50);
      padding-bottom: rem-calc(30);
      background: $white;
      margin-bottom: rem-calc(20);
      margin-top: rem-calc(-20);
    }
    article.node-teaser {
      display: block;
    }
  }
}
