section.paragraph.paragraph-content-slider {
  .slider-wrapper {
    position: relative;
  }

  .upper-bound {
    padding-top: var(--module-padding-m);
  }

  .content-slide-inner {
    display: flex;
    flex-direction: column;
    gap: var(--module-padding-m);

    > img {
      padding: 0 4rem;
    }
  }

  .icon-text {
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(32);
  }

  .icon-text-element {
    display: flex;
    gap: rem-calc(16);
    flex-basis: calc(50% - #{rem-calc(16)});

    @include media-breakpoint-down(md) {
      flex-basis: 100%;
    }

    .icon-wrapper {
      flex-basis: rem-calc(64);
    }

    .text-wrapper {
      flex-basis: fit-content;
      .text {
        @include body(xs);
      }
    }
  }

  .text {
    max-width: rem-calc(1000);
  }

  .dots {
    display: flex;
    list-style: none;
    margin: 0 auto;
    text-align: left;
    z-index: 5;
    bottom: unset;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    li {
      .active {
        background: $dark-blue;
        color: $dark-blue;
      }
      button {
        background: $light-blue;
        height: rem-calc(10);
        width: rem-calc(40);
        overflow: hidden;
        color: $light-blue;
        border: none;
        transition-duration: var(--transition-duration);
        &:hover {
          background: $dark-blue;
          color: $dark-blue;
          transform: scale(1.05);
        }
      }
    }
  }

  .slider-nav-button {
    position: absolute;
    z-index: 10;
    top: rem-calc(64);
    background-color: var(--theme-1-lighter-10);
    border: 0;
    outline: 0;
    color: var(--white);

    &:hover {
      background-color: var(--theme-1);
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
}
