section.paragraph.paragraph-tabs {
  .tab-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    gap: var(--module-padding-xs);
    margin-bottom: var(--module-padding-l);
  }

  .icon-wrapper {
    width: 30%;
    max-width: 8rem;
  }
  
  h3 {
    width: calc(70% - var(--module-padding-xs));
  }

  .text {
    width: 100%;
  }
}
