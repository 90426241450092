:root {
  /* Colors */
  --theme-1: #0468b1;
  --theme-2: #e31418;
  --theme-3: #fecc00;

  --theme-1-darker-30: #11466b;
  --theme-1-lighter-10: #2093d1;
  --theme-1-lighter-20: #9bc9e9;
  --theme-1-lighter-25: #D4E7F4;
  --theme-1-lighter-30: #E6F0F7;
  --theme-1-transparent: #0468b1aa;

  --theme-2-lighter-20: #f79391;

  --theme-3-darker-10: #d2a000;

  --white: #ffffff;
  --grey-50: #7b7b7b;
  --black: #000000;

  --error: var(--theme-2);

  --footer-bg: #011f35;

  /* Layout */
  --module-padding-xs: .5rem;
  --module-padding-s: 1rem;
  --module-padding-m: 2rem;
  --module-padding-l: 4rem;
  --module-padding-xl: 8rem;
  --module-padding-xxl: 12rem;

  @include media-breakpoint-down(lg) {  
    --module-padding-xs: .5rem;
    --module-padding-s: 0.5rem;
    --module-padding-m: 1rem;
    --module-padding-l: 2rem;
    --module-padding-xl: 4rem;
    --module-padding-xxl: 6rem;
  }

  --link-padding: 0.5rem 1rem;

  @include media-breakpoint-down(lg) {
    --link-padding: 1rem 2rem;
  }

  --transition-duration: 0.3s;
}
