@use "sass:math";

$gatterwidth: rem-calc(68);
$borderradius: rem-calc(6);

$ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
$ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);

$black50: rgb(157, 157, 157);
$black30: rgb(200, 200, 200);
$black40: rgb(180, 180, 180);

html,
body {
  -webkit-font-smoothing: subpixel-antialiased;
  //overflow-y: inherit !important;
}

.header {
  display: flex;
  position: fixed;
  left: calc(1% + #{$grid-gutter-width} / 2);
  right: 2.5%;
  top: calc(1vw + #{$grid-gutter-width} / 4);
  @include media-breakpoint-up(md) {
    top: calc(1vw + #{$grid-gutter-width} / 2);
  }
  margin-top: rem-calc(8);
  padding-right: rem-calc(16);
  z-index: 20;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .function {
      width: 100%;
      display: flex;

      .view-chooser {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          @include body(s);
          font-family: "Calibri", sans-serif;

          img {
            width: rem-calc(60);
            height: rem-calc(60);
          }
        }
      }

      .item {
        margin-right: rem-calc(15);
        padding: rem-calc(19);
        border-radius: $borderradius;
        display: flex;
        align-items: center;
        @include body(s);
        font-family: "Calibri", sans-serif;
        letter-spacing: -0.01em;

        &.language-switch,
        &.search {
          border-radius: rem-calc(60);

          a {
            margin-right: 0;
          }
        }

        &.search {
          padding-top: rem-calc(14);
          padding-bottom: rem-calc(14);
        }
      }
    }
  }
}

.header {
  .cloud-icon,
  .list-icon {
    display: block;
    position: relative;
    opacity: 0.15;
    transition: all 0.2s ease;

    &:active,
    &.active {
      opacity: 0.5;
    }

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    path {
      fill: black;
    }
  }

  .list-icon {
    margin-left: rem-calc(15);
  }
}

.view-chooser {
  background: $dark;
  color: white;

  &:hover {
    .cloud-icon,
    .list-icon {
      transform: scale(1.1);

      &.active {
        transform: scale(0.9);
      }
    }
  }
}

.language-switch {
  overflow: hidden;
  width: rem-calc(78);
  position: relative;

  a {
    color: inherit;
  }

  &__current,
  &__other {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  &__current {
    opacity: 1;
  }

  &__other {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  &:hover {
    .language-switch {
      &__current {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
      }

      &__other {
        transform: translate3d(0, 0, 0);
        opacity: 1;

        a {
          transition: opacity 0.15s ease;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.cloud-view {
  background: $white;
  max-width: 100%;
  //width: 110vw;
  //padding: rem-calc(60) !important;
  &.detail-opened {
    .cloud-teaser:not(.active) {
      opacity: 0;
      pointer-events: none;
    }

    .cloud-teaser.active {
      z-index: 50;

      .teaser-wrapper {
        top: var(--active-teaser-top) !important;
        left: var(--active-teaser-left) !important;
        pointer-events: none;
      }

      .cloud-content-teaser {
        > div {
          transform: scale(1.5) !important;
        }
      }
    }
  }

  .cloud-teaser {
    min-height: calc(#{$gatterwidth} * 5);
    min-width: calc(#{$gatterwidth} * 4);
    margin-bottom: rem-calc(32);
    position: relative;
    transition: 500ms opacity;

    &:hover {
      z-index: 3;
    }

    .next-teaser {
      display: none;
    }

    .teaser-wrapper {
      width: 20vw;
      transform: scale(0) translateX(0);
      opacity: 0;
      transition: all 350ms cubic-bezier(0, 1.3, 0.89, 1);
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 0;

      &.padding-top {
        padding-top: rem-calc(50);
      }

      &.scaled {
        transform: scale(1) translateX(0);
        opacity: 1;
        animation: wobble 1.4s $ease-in-out-quad 1;
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        background-color: gray;
        max-width: none;
      }
    }
  }

  .cloud-wrapper {
    min-height: 75vh;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 0;
  }

  .cloud-content-teaser {
    &:hover {
      .teaser-text {
        transform: translateX(-50%) scale(1);
      }
    }

    .teaser-text {
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scale(0);
      margin-top: 33%;
      bottom: -10%;
      border-radius: rem-calc(20);
      transition: all 350ms cubic-bezier(0, 2.03, 0.89, 1);

      h2 {
        @include body(s);
        color: $dark;
        padding: rem-calc(15);
        width: auto;
        text-align: center;
        margin-bottom: 0;
      }
    }

    .inner-wrapper {
      img {
        border-radius: rem-calc(40);
        overflow: hidden;
      }
    }
  }
}

.list-view {
  h2 {
    @include body(xxl);
    font-weight: bold;
    color: white;
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(20);
  }

  #list-section {
    background: $pale-grey;
    overflow: hidden;
    padding: rem-calc(80) 0;

    .container-fluid {
      max-width: 98%;
    }

    .sorting {
      padding-bottom: rem-calc(20);
      position: fixed;
      z-index: 4;
      top: rem-calc(120);
      padding-right: rem-calc(16);
      //left: 1.25%;
      //right: 2.5%;
      //padding-left: $grid-gutter-width/2;
      //padding-right: $grid-gutter-width/2;
      display: flex;
      flex-flow: wrap;
      align-items: center;

      .sort-button {
        background: white;
        padding: rem-calc(5);
        @include media-breakpoint-up(md) {
          padding: rem-calc(20);
        }
        margin-left: rem-calc(10);
        border-radius: rem-calc(4);
        @include body(m);
      }

      span {
        cursor: pointer;
        @include body(xxl);
        font-weight: 900;
        color: white;
        transition: opacity 0.25s ease;
        display: inline-flex;
        flex-flow: wrap;

        &.active,
        &:hover {
        }
      }
    }

    h2 {
      @include body(xxl);
      font-weight: 900;
    }

    .list-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: rem-calc(40);
      border-bottom: rem-calc(1) solid $black;
      padding-bottom: rem-calc(40);

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }

      a {
        color: black;
        width: 100%;

        &:hover {
          text-decoration: none;
        }
      }

      .bottom-wrapper {
        margin-top: rem-calc(2);
      }

      .teaser-wrapper {
        position: relative;
        cursor: pointer;

        h3 {
          @include body(l);
          font-weight: 900;
          margin-bottom: rem-calc(15);
        }

        .item-text {
          display: flex;
          flex-flow: column;
          @include media-breakpoint-up(md) {
            justify-content: center;
            padding-left: calc(#{$whitespace} * 2);
          }
        }

        .item-image {
          min-height: rem-calc(250);

          .list-img {
            border-radius: $borderradius;
            @include media-breakpoint-up(md) {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        img {
          border-radius: $borderradius;
        }

        .wrapper {
          display: flex;
          padding: rem-calc(6) 0 rem-calc(3);
          flex-wrap: wrap;

          .cat-item {
            margin-right: rem-calc(4);
            background-color: white;
            color: rgb(27, 27, 27);
            font-size: rem-calc(14);
            font-weight: bold;
            border-radius: rem-calc(5);
            margin-top: rem-calc(4);
            @include body(s);
            line-height: 1;
            font-family: "Calibri", sans-serif;
            padding: rem-calc(4) rem-calc(6);

            &.year {
              font-weight: bold;
            }
          }
        }

        .text {
          font-size: rem-calc(16);
          letter-spacing: 0;
        }
      }

      &.promoted {
        a {
          width: 100%;
        }
      }
    }
  }
}

/*Search*/

.search {
  position: relative;
  color: $black50;
  cursor: text;
  transition: 250ms max-width;

  &.item {
    min-width: 30vw;
    width: auto;
  }

  .resultwrapper {
    display: flex;

    .filter-item {
      cursor: pointer;
      margin-right: rem-calc(10);
      padding: rem-calc(5) rem-calc(12);
      border-radius: rem-calc(6);
      color: white;
      font-size: rem-calc(13);
    }
  }

  .fallback-label {
    pointer-events: none;
  }

  .search-wrapper {
    position: absolute;
    top: rem-calc(80);
    left: 0;
    width: calc(
      #{96.25}vw - #{rem-calc(268)} - #{rem-calc(8)} - #{rem-calc(16)}
    ); // width of header - left offset of search bar
    background-color: rgba(white, 0.95);
    border-radius: $borderradius;
    cursor: default;

    &.enter {
      opacity: 0;
      transform: scale(0.9);
    }

    &.enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }

    &.exit {
      opacity: 1;
    }

    &.exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }

    .scroll-wrapper {
      overflow-y: auto;
      padding: rem-calc(55);

      .filter-option-wrapper {
        .filter-option {
          display: flex;
          flex-wrap: wrap;
          font-size: rem-calc(33);
          font-family: Raleway, sans-serif;
          font-weight: 700;

          label,
          .option {
            margin-right: rem-calc(11);
            margin-bottom: rem-calc(11);
            padding: rem-calc(20);
            border-radius: rem-calc(6);
            color: white;
          }

          label {
            background-color: lightgray;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .option {
            cursor: pointer;
            background-color: grey;
            transition: opacity 0.25s ease;
            line-height: 1;

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  input[name="text-title"] {
    border: none;
    outline: none;
    font-weight: bold;
    background-color: transparent !important;

    &:-webkit-autofill {
      background-color: transparent !important;
      color: black !important;
    }
  }
}

.filter-item {
  transition: opacity 0.25s ease;
  opacity: 1;

  .x-icon {
    display: inline-block;
    width: rem-calc(10.2);
    height: rem-calc(11.6);
    margin-left: rem-calc(8);
    transition: all 0.25s ease;
    opacity: 0.1;

    > path {
      fill: black;
    }
  }

  &:hover {
    opacity: 0.75;

    .x-icon {
      opacity: 0.3;
    }
  }
}

/*Dialogelements*/

.dialogue-wrapper {
  left: calc(1.25% + #{rem-calc(8)});
  bottom: calc(1.25vw + #{rem-calc(8)});
  position: fixed;
  z-index: 4;
  margin-right: rem-calc(16);
  transition: all 0.5s $ease-out-quad;
  transform: translate3d(0, 300%, 0);

  &.slide-in-first {
    transform: translate3d(0, rem-calc(90), 0);
    @include media-breakpoint-down(md) {
      transform: translate3d(0, rem-calc(80), 0);
    }
  }

  &.slide-in-second {
    transform: translate3d(0, 0, 0);
  }
}

.dialog-element {
  p {
    line-height: 1;
    padding: rem-calc(18) rem-calc(30);
    background-color: white;
    opacity: 0.95;
    margin-top: rem-calc(10);
    border-radius: rem-calc(25);
    margin-bottom: 0;
    width: fit-content;
    font-family: Raleway, sans-serif;
    font-weight: 700;
    font-size: rem-calc(33);
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: -0.015em;

    a {
      border-bottom: rem-calc(3) solid;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/*Elastic band effect*/
.in-view {
  transition: all 0.8s $ease-out-quad;
  transform: translateY(0);
  will-change: transform;

  &.not-in-view {
    transform: translateY(rem-calc(80));
  }
}

.main-content.show-menu {
  #edge-menu {
    opacity: 1 !important;
  }
}

/* Edge Menu */
#edge-menu {
  position: fixed;
  right: 0;
  opacity: 0;
  transition: 500ms opacity;
  @include media-breakpoint-up(lg) {
    right: rem-calc(-12);
  }
  top: rem-calc(350);
  z-index: 99;
  width: rem-calc(60);

  ul {
    transform: rotate(-90deg);
    margin-bottom: 0;
    display: flex;
  }

  li {
    border-top-right-radius: rem-calc(10);
    border-top-left-radius: rem-calc(10);
    margin-right: rem-calc(5);
    height: rem-calc(43);
    transform: translate3d(0, rem-calc(3), 0);
    transition: transform 0.25s ease;
  }

  a {
    display: block;
    padding: rem-calc(10);
    color: white;
    font-weight: bold;
    font-size: rem-calc(16);
    transition: color 0.15s ease;
    text-decoration: none;
  }
}

.lz-button {
  background-color: white;
  cursor: pointer;
  height: rem-calc(58);
  width: rem-calc(68);
  border-radius: rem-calc(60);
  margin-right: rem-calc(15);
  position: relative;

  .play,
  .pause {
    display: block;
    height: auto;
    width: rem-calc(21);
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.25s ease;

    &.active {
      opacity: 1;
    }
  }

  .play {
    transform: translate3d(-35%, -50%, 0);
  }
}

@media (any-pointer: fine) {
  .lz-button {
    opacity: 0.75;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 1;
    }
  }

  #edge-menu {
    li {
      &:hover {
        transform: translate3d(0, 0, 0);
      }

      &.active {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

@keyframes wobble {
  0% {
    transform: scale(0.5) rotate(0deg);
  }
  25% {
    transform: scale(1.16) rotate(2deg);
  }
  45% {
    transform: scale(0.98) rotate(-1deg);
  }
  65% {
    transform: scale(1.03) rotate(0.5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.close-icon {
  padding: rem-calc(8);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: rem-calc(60);
  margin: rem-calc(-8) rem-calc(-16);
  cursor: pointer;

  svg {
    width: rem-calc(24);
    height: rem-calc(24);
  }
}

@include media-breakpoint-down(md) {
  .header {
    .wrapper {
      .function {
        flex: 0 0 100%;
        justify-content: space-between;

        .view-chooser {
          span {
            &:first-child {
              display: none;
            }

            &.cloud-icon {
              margin-left: 0;
            }
          }
        }

        .item {
          margin-right: rem-calc(15);
          padding: rem-calc(19);
          border-radius: $borderradius;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: rem-calc(18);
          letter-spacing: -0.01em;

          &.language-switch,
          &.search {
            border-radius: rem-calc(60);

            a {
              margin-right: 0;
            }
          }

          &.search {
            width: rem-calc(420);
            padding-top: rem-calc(14);
            padding-bottom: rem-calc(14);
          }
        }

        .search {
          &.active {
            margin-right: 0;
            width: 100% !important;
            display: flex;
            justify-content: space-between;

            .search-wrapper {
              width: calc(100vw - #{rem-calc(32)});
              min-height: 120vh;
              height: 100%;

              .scroll-wrapper {
                height: 80%;
                padding: rem-calc(15);

                .filter-option-wrapper {
                  .filter-option {
                    flex-direction: column;

                    label,
                    .option {
                      margin-right: 0;
                    }
                  }
                }
              }

              .x-icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .cloud-view {
    max-width: none;
    width: 100%;

    .cloud-teaser {
      min-width: auto;
    }

    .cloud-wrapper {
      min-height: 101vh;
    }
  }
}

@media screen and (max-width: rem-calc(500)) {
  .story-full {
    .header .wrapper .lorenz-logo {
      width: 22vw;
      height: math.div(454, 1000) * 22vw;
    }
  }
}
