section.paragraph-kalender-abschnitt {
  &.heading-only {
    padding-bottom: 0;
  }
  h2 {
    padding: rem-calc(50) rem-calc(30) rem-calc(40) rem-calc(30);
    @include headline(m);
    background: $white;
    margin-bottom: rem-calc(40);
  }
  .node-teaser.teaser-veranstaltung {
    display: block;
    margin-bottom: rem-calc(24);
  }
}
