section.paragraph.paragraph-icon-text {
  .item {
    margin-bottom: var(--module-padding-l);
  }

  .icons {
    display: flex;
    margin-bottom: rem-calc(20);

    .icon-wrapper {
      width: 60px;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}
