.fade-wrapper-section-heading + .fade-wrapper-text section.paragraph.paragraph-text,
.fade-wrapper-section-heading + .fade-wrapper-hero-text section.paragraph.paragraph-hero-text{
  padding-top: 0;
}

.fade-wrapper-bilder-slider + .fade-wrapper-section-heading section.paragraph.paragraph-sectionheading {
  padding-top: var(--module-padding-m);
}

section.paragraph.paragraph-sectionheading {
  padding: var(--module-padding-l) 0 var(--module-padding-m) 0;

  h2 {
    margin: 0;
    padding: 0;
    @include headline(l);
  }
}
