section.paragraph.paragraph-akkordion-text,
.paragraph-audio {
  .accordion__item {
    border-top: rem-calc(1) solid $greyish;
    &:last-child {
      border-bottom: rem-calc(1) solid $greyish;
    }
    .accordion__heading {
      @include body(s);
      padding: rem-calc(10) rem-calc(15);
      cursor: pointer;
    }
    .accordion__button {
      outline: none;
      &:focus,
      &:focus-visible {
        outline: rem-calc(1) solid;
      }
    }
    .accordion__panel {
      padding: 0 rem-calc(15);
    }
  }
}
