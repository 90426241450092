section.paragraph.paragraph-number-facts {
  .number-fact {
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: rem-calc(30) 0;
      border-bottom: rem-calc(2) solid $white-two;
      margin: 0 auto;
    }
    .number {
      @include headline(l);
      font-weight: 700;
    }
    .label {
      @include body(s);
    }
    &.main {
      .number {
        @include headline(xl);
      }
    }
  }
  .small {
    @include media-breakpoint-up(md) {
      border-left: rem-calc(2) solid $white-two;
      .number-fact {
        margin-bottom: rem-calc(65);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
