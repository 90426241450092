article.node.node-teaser.teaser-solution {
  height: 100%;

  img {
    width: initial;
    transition: 0.3s all;
  }

  .title-wrapper {
    position: relative;

    @include media-breakpoint-up(lg) {
      h3 {
        width: 50%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .card-body > div {
      width: 50%;
    }
  }

  .image-overlay {
    position: absolute;
    width: 40%;
    max-width: rem-calc(250);
    right: 0;
    top: calc(-1 * var(--module-padding-l));
    z-index: 2;
  }
}
