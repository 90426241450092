/**
 * Teaser Views modes.
 */
article.teaser-news.teaser-news-timeline,
article.teaser-news.teaser-news-timeline-list {
  .tag {
    background-color: $white-three;
    color: $greyish;
    padding: rem-calc(2) rem-calc(6);
    @include body(xs);
    margin-right: rem-calc(10);
    border-radius: rem-calc(12);

    &:before {
      content: "#";
    }
  }

  h3 {
    @include body(m);
    margin-top: rem-calc(20);
  }

  &.teaser-news.teaser-news-timeline-list {
    h3 {
      margin-top: 0;
    }

    img {
      margin: 0;
    }
  }
}

article.teaser-news {
  &:not(:last-child) {
    margin-bottom: var(--module-padding-l);
  }

  .time-wrapper {
    @include body(xxs);
    margin-bottom: var(--module-padding-s);
    margin-top: var(--module-padding-l);
  }

  margin-left: calc(var(--module-padding-m) * -1);
  margin-right: calc(var(--module-padding-m) * -1);
  background-color: var(--white);
  padding-left: var(--module-padding-m);
  padding-right: var(--module-padding-m);
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);

  .banner {
    top: rem-calc(-20);
  }
}
