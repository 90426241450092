section.paragraph.paragraph-intro {
  background: url("/intro-overlay.svg"), var(--bg-img, var(--theme-1));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--module-padding-xl) 0;

  * {
    color: var(--white);
  }

  h1 {
    margin-bottom: var(--module-padding-m);
  }

  .container > .row {
    position: relative;
  }

  .image-overlay {
    position: absolute;
    right: 0;

    &.overlay-solution {
      max-width: 30%;
      top: 65%;
    }

    &.overlay-product {
      top: 20%;
      max-width: 50%;
      width: 50%;
    }
  }
}
