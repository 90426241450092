.fade-wrapper-click-suggestions_detached
  + .fade-wrapper-call-to_action
  section.paragraph.paragraph-call-to-action {
  margin-top: 0;
}

section.paragraph.paragraph-call-to-action {
  background-color: var(--theme-1);
  margin: var(--module-padding-l) 0;

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    padding: var(--module-padding-l) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      color: var(--white);
    }
  }
}
.banner {
  position: absolute;
  top: -50px;
  background-color: var(--theme-3);
  padding: var(--module-padding-s);

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-bottom: 10px solid var(--theme-3-darker-10);
    border-left: 10px solid var(--theme-3-darker-10);
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    top: 0;
    right: -20px;
  }
}
