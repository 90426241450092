body.fullscreen-menu-open {
  overflow-y: hidden !important;
  //max-height: 100vh;
}

#fullscreen-menu {
  background: white;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: -150%;
  left: 0;
  z-index: 10;
  transition: all 500ms ease;
  overflow-y: scroll;
  height: 100%;
  &.active {
    top: 0;
  }
  .nav-wrap {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 100vh;
    nav.main-menu-desktop {
      padding-top: rem-calc(150);
      ul {
        display: block !important;
        li {
          margin-bottom: rem-calc(35);
          position: relative;
          ul {
            transition: opacity 250ms ease;
            opacity: 0;
            position: absolute;
            top: 0;
            visibility: hidden;
            z-index: 10;
            &.active {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
    div.often-clicked {
      background: $white;
      padding: rem-calc(30) 0 rem-calc(50) 0;
      h2 {
        @include body(s);
        margin-bottom: rem-calc(40);
      }
      nav.often-clicked {
        ul {
          display: flex;
          li {
            margin-right: rem-calc(25);
            margin-bottom: rem-calc(35);
          }
        }
      }
    }
  }
}
