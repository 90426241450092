section.paragraph-teaser-list {
  .more {
    margin-top: rem-calc(30);
    @include media-breakpoint-up(md) {
      margin-top: rem-calc(60);
    }
  }
}

section.paragraph-teaser-list.paragraph-teaser-list-slick {
  /* the slides */
  .slick-slide > div {
    padding: 0 rem-calc(35);
  }
  /* the parent */
  .slick-list {
  }
}

.paragraph-teaser-list-veranstaltung {
  article.node {
    margin-bottom: 2rem;
  }
}

.paragraph-teaser-list-news {
  padding-top: var(--module-padding-l);
  padding-bottom: var(--module-padding-l);
  background-color: var(--theme-1-lighter-30);

  h2 {
    margin: var(--module-padding-l) 0;
  }

  .load-more-wrapper {
    margin-top: var(--module-padding-l);
  }

  .loader-wrapper svg {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: var(--module-padding-s);
  }

  .logo_partner {
    img {
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.paragraph-teaser-list-blog {
  .pagination {
    display: flex;
    column-gap: 10px;

    li {
      flex-basis: 48px;
      width: 48px;
      height: 48px;

      &.previous,
      &.next {
        flex-basis: auto;
        width: auto;
        height: 48px;
      }

      &.selected {
        background-color: var(--theme-1);
        a {
          color: var(--white);
          &:hover {
            background-color: var(--theme-1-lighter-10);
          }
        }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--theme-1);
        padding: var(--module-padding-s);
        @include body(xs);

        &:hover {
          text-decoration: none;
          background-color: var(--theme-1-lighter-30);
        }
      }
    }
  }
}
