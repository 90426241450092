@mixin footer-link($level) {
  margin: var(--link-padding);

  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }

  @if $level == 1 {
    @include body(xs);
    font-weight: bold;
  }

  @if $level == 2 {
    @include body(xxs);
    font-weight: light;
    opacity: 0.7;
  }
}

footer#page-footer {
  * {
    color: var(--white);    
    display: block;
    line-height: 1.2;
  }

  .footer-main {
    background-color: var(--footer-bg);
    position: relative;
    padding: var(--module-padding-l) 0;

    nav.footer-nav-main {
      width: 100%;

      ul.level-1 {
        display: flex;

        > li {
          width: 33%;
        }
      }
    }

    ul.level-1 {
      margin-bottom: 0;

      > li {
        a {
          @include footer-link(1);
        }
      }
    }

    ul.level-2 {
      margin-bottom: 0;

      > li {
        a {
          display: block;
          @include footer-link(2);
        }
      }
    }

    @include media-breakpoint-down(md) {
      li {
        text-align: center;
      }

      .footer-main-inner {
        flex-direction: column;
        align-items: center;
      }

      ul.level-1 > li {
        margin-bottom: var(--module-padding-l);
        width: 100% !important;
      }

      nav.footer-nav-products {
        width: 100%;
      }

      nav.footer-nav-main {
        width: 100%;
      }

      ul.level-1 {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .footer-legal {
    background-color: var(--theme-1);
    padding: var(--module-padding-s) 0;

    .footer-legal-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      nav {
        display: flex;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        p {
          @include footer-link(2);
        }
      }

      .logo-wrapper {
        width: rem-calc(120);

        @include media-breakpoint-down(md) {
          margin-top: var(--module-padding-l);
        }
      }
    }

    nav {
      ul {
        display: flex;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: center;
        }

        li {
          a {
            @include footer-link(2);
          }
        }
      }
    }
  }
}
