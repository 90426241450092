section.paragraph.paragraph-download-schublade {
  header {
    @include body(xs);
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: space-between;
    }
    time {
      padding-right: rem-calc(20);
    }
  }
  h3 {
    margin-top: rem-calc(12);
    @include body(s);
    margin-bottom: rem-calc(20);
  }
  .text {
    margin-bottom: rem-calc(32);
  }
  .row.downloads {
    > div {
      margin-bottom: rem-calc(32);
    }
    .download {
      color: black;
      text-decoration: none;
      background: $white;
      height: 100%;
      display: flex;
      flex-flow: column;
      min-height: rem-calc(180);
      &:hover {
        color: black;
        text-decoration: none;
      }
      .text-image {
        @include body(l);
        text-align: center;
        margin: auto;
        text-transform: uppercase;
      }
      .download-title {
        padding: rem-calc(8);
        @include body(xs);
        text-align: center;
        @include media-breakpoint-up(md) {
          padding: rem-calc(24);
        }
      }
    }
  }
}
