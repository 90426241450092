section.paragraph.paragraph-intro-slider {
  position: relative;
  padding: 0 0 var(--module-padding-l) 0;
  .row {
    align-items: center;
  }
  .bg-img {
    z-index: 0;
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
  }
  .upper-bound {
    padding-bottom: var(--module-padding-s);
  }
  .container {
    .dots {
      display: flex;
      position: absolute;
      list-style: none;
      margin: 0 auto;
      text-align: left;
      z-index: 5;
      bottom: unset;
      li {
        margin-right: rem-calc(40);
        .active {
          background: $dark-blue;
          color: $dark-blue;
        }
        button {
          background: $light-blue;
          height: rem-calc(10);
          width: rem-calc(40);
          overflow: hidden;
          color: $light-blue;
          border: none;
          transition-duration: var(--transition-duration);
          &:hover {
            background: $dark-blue;
            color: $dark-blue;
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .slick-slider {
    display: flex;
    justify-content: center;
  }
  .container {
    padding-top: var(--module-padding-l);
    .row {
      @include media-breakpoint-down(md) {
        .vanished {
          display: none;
        }
      }
      .text-part {
        .headline {
          @include headline(xl);
          color: var(--white);
          font-weight: 300;
        }
        .underline {
          @include body(s);
          color: var(--white);
          margin: rem-calc(40) 0;
        }
      }
    }
  }
}
