section.paragraph.paragraph-strukturierte-liste {
  padding: var(--module-padding-xl) 0;

  .list-banner {
    background-color: var(--theme-1);
    padding: var(--module-padding-s) 40px;
    position: relative;

    h3 {
      font-weight: bold;
      color: var(--white);
      padding: 0;
      margin: 0;
    }

    &::after {
      content: "";
      position: absolute;
      transform: translate(-40px, var(--module-padding-s));
      border-style: solid;
      border-width: 0 40px 40px 0;
      border-color: transparent var(--theme-1-lighter-10) transparent transparent;
    }
  }

  .content {
    @include body(xxs);

    .text {
      margin: 60px 0 var(--module-padding-l) 40px;
    }

    table {
      width: 100%;

      td {
        padding-bottom: rem-calc(15);
      }

      tr td:nth-child(1) {
        padding-right: rem-calc(15);
      }

      @include media-breakpoint-up(lg) {
        @include make-col-offset(1);
      }
    }
  }
}
